@font-face {
  font-family: 'ATTAleckSans_Rg';
  src: url('./fonts/attalecksans_rg-webfont.woff2') format('woff2'),
        url('./fonts/attalecksans_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ATTAleckSans_Md';
  src: url('./fonts/attalecksans_md.woff2') format('woff2'),
        url('./fonts/attalecksans_md.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ATTAleckSans_Bd';
  src: url('./fonts/attalecksans_bd.woff2') format('woff2'),
        url('./fonts/attalecksans_bd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'ATTAleckSans_Rg';
}
